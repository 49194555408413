import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgRibbon from '../generated/svg/ribbon'
import { AssetImage } from './image'
import { ll } from '../utils/lcqw'
import { Animate } from './animate'
import { slideinFromBottom, slideinFromLeft, slideinFromRight, zoomSlideinFromBottom } from '../styles/keyframes'
import { Link } from './link'

const Outer = styled.div`
  background-color: white;
  width: ${ll(850)};
  @media (max-width: 40rem){
    width: 100%;
  }
`

const Inner = styled.div`
  padding: ${ll(29)} ${ll(68)};
  border: 0.125rem solid var(--orange);
  border-radius: ${ll(12.75)};
  @media (max-width: 40rem){
    padding: ${ll(30)};
  border-radius: ${ll(10)};
  }
`

const HeadContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-top: ${ll(-55)};
  opacity: 0;
.animate > & {
  animation: ${zoomSlideinFromBottom} 1s 0.4s both;
}
`

const RibbonContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-15%);
  svg {
    display: block;
    width: auto;
    height: ${ll(77)};
    @media (max-width: 40rem){
      height: ${ll(50)};
    }
  }
`

const HeadLabel = styled.div`
position: relative;
z-index: 1;
color: white;
font-size: ${ll(28.900001525878906)};
line-height: 1.3078723203733071;
font-weight: 700;
@media (max-width: 40rem){
  font-size: ${ll(18.527387619018555)};
line-height: 1.3078723866643016;
font-weight: 700;
}
`

const Title = styled.div`
  font-size: ${ll(27.656715393066406)};
  line-height: 1.5520643880407865;
  font-weight: 700;
  margin-top: ${ll(55)};
  text-align: center;
  @media (max-width: 40rem){
    font-size: ${ll(19.718116760253906)};
    line-height: 1.5520641766102674;
    font-weight: 700;
  margin-top: ${ll(30)};
  }
  strong {
    font-size: ${ll(38.25)};
    line-height: 1.1222223020067401;
    font-weight: 700;
    color: var(--green);
    @media (max-width: 40rem){
      font-size: ${ll(27.27069854736328)};
      line-height: 1.122222175594676;
      font-weight: 700;
    }
    small {
      font-size: ${ll(27.656715393066406)};
      line-height: 1.5520643880407865;
      font-weight: 700;
      margin-top: ${ll(55)};
      text-align: center;
      @media (max-width: 40rem){
        font-size: ${ll(19.718116760253906)};
        line-height: 1.5520641766102674;
        font-weight: 700;
      }
    }
  }
`

const Subslabel = styled.div`
font-size: ${ll(18.700000762939453)};
letter-spacing: 0.07em;
font-weight: 700;
display: inline;
background-color: var(--brown);
color: white;
padding: ${ll(7)} calc(${ll(18)} - 0.07em) ${ll(7)} ${ll(18)};
margin-right: ${ll(8)};
vertical-align: middle;
@media (max-width: 40rem){
  font-size: ${ll(14)};
letter-spacing: 0.07em;
font-weight: 700;
display: block;
width: fit-content;
margin-left: auto;
margin-right: auto;
padding: ${ll(4)} calc(${ll(15)} - 0.07em) ${ll(2)} ${ll(15)};
margin-bottom: ${ll(4)};
}
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 ${ll(42)};
margin-top: ${ll(29)};
align-items: center;
@media (max-width: 40rem){
margin-top: ${ll(19)};
grid-template-columns: 1fr;
  gap: ${ll(26)};
  
}
`

const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
    filter: drop-shadow(${ll(3)} ${ll(3)} ${ll(2)} #0000004d);
    @media (max-width: 40rem){
      width: ${ll(240)};
      margin-left: auto;
      margin-right: auto;
    }
  }
  opacity: 0;
.animate & {
  animation: ${slideinFromLeft} 1s 0.4s both;
}
`

const CardContainer = styled.div`

opacity: 0;
.animate & {
  animation: ${slideinFromRight} 1s 0.4s both;
}
`

const Card = styled.div`
border-radius: ${ll(8)};
border: 0.125rem solid var(--red);
overflow: hidden;
`

const CardHead = styled.div`
font-size: ${ll(13.249695777893066)};
line-height: 1.6111111870869101;
font-weight: 700;
background-color: var(--red);
padding-block: ${ll(4)};
text-align: center;
color: white;
`

const CardText1 = styled.div`
font-size: ${ll(13.249695777893066)};
line-height: 1.6111111870869101;
font-weight: 500;
margin-top: ${ll(12)};
text-align: center;
small {
  font-size: ${ll(8.833130836486816)};
line-height: 2.416666693658068;
font-weight: 500;
}
del {
  text-decoration: none;
  display: inline-block;
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--red);
    display: block;
    inset: 0;
    margin: auto;
    position: absolute;
    transform: translateY(-200%);
  }
}

`

const CardInner = styled.div`
margin-top: ${ll(4)};
display: grid;
grid-template-columns: auto auto;
justify-content: center;
gap: ${ll(9)};
`

const OffCircle = styled.div`
font-size: ${ll(17.740703582763672)};
line-height: 1.0291848246437731;
letter-spacing: 0.04em;
font-weight: 700;
text-align: center;
aspect-ratio: 1 / 1;
width: ${ll(82)};
background-color: var(--red);
color: white;
border-radius: 100rem;
place-content: center;
padding-top: ${ll(8)};
box-sizing: border-box;
small {
  font-size: ${ll(12.575982093811035)};
line-height: 1.4518518529753035;
letter-spacing: 0.1em;
font-weight: 700;
}
strong {
  font-size: ${ll(29.343963623046875)};
line-height: 0.6222221081116497;
font-weight: 700;
}
`

const CardText2 = styled.div`
font-size: ${ll(11.041413307189941)};
font-weight: 500;
`

const CardPriceContainer = styled.div`

`

const CardPrice = styled.div`
  display: flex;
  align-items: baseline;
`

const CardPriceNum = styled.div`
font-size: ${ll(53.73487854003906)};
line-height: 1.0684930495469491;
font-weight: 700;
color: var(--red);
`

const CardPriceUnit = styled.div`
font-size: ${ll(29.003965377807617)};
line-height: 0.9700000286102295;
font-weight: 700;
color: var(--red);
position: relative;
&::before {
  content: '(税込)';
  display: block;
  font-size: ${ll(10.772900581359863)};
line-height: 0.9700000286102295;
font-weight: 500;
position: absolute;
white-space: nowrap;
bottom: 100%;
}
`

const CardText3 = styled.div`
font-size: ${ll(10.305319786071777)};
line-height: normal;
font-weight: 500;
color: var(--red);
border: 1px solid var(--red);
padding-block: ${ll(2)};
border-radius: 100rem;
text-align: center;
`

const CardBottom = styled.div`
background-color: #FFE8E8;
padding: ${ll(9)};
display: flex;
flex-direction: column;
align-items: center;
margin-top: ${ll(14)};
`

const CardText4 = styled.div`
font-size: ${ll(14.01832389831543)};
line-height: 1.522773567518078;
font-weight: 700;
color: var(--red);
`

const CardText5 = styled.div`
font-size: ${ll(16.914358139038086)};
line-height: 1.6111111925525443;
font-weight: 700;
color: var(--red);
background-color: #fff;
border-radius: 100rem;
padding: ${ll(2)} ${ll(26)};
margin-top: ${ll(5)};
strong {
  font-size: ${ll(24.773555755615234)};
line-height: 1.100000015398263;
font-weight: 700;
}
small {
  font-size: ${ll(11.560992240905762)};
line-height: 2.357142980878904;
font-weight: 700;
}
`

const CardText6 = styled.div`
font-size: ${ll(13.249695777893066)};
line-height: 1.6111111870869101;
font-weight: 500;
color: var(--red);
margin-top: ${ll(5)};
strong {
  font-size: ${ll(13.249695777893066)};
line-height: 1.6111111870869101;
font-weight: 700;
}
`

const ButtonContainer = styled.div`
  grid-area: 2 / 2;
margin-top: ${ll(18)};
  @media (max-width: 40rem){
    grid-area: auto;
    margin-top: 0;
  }
`

const Button = styled(Link)`
display: block;
font-size: ${ll(15.300000190734863)};
line-height: normal;
letter-spacing: 0.03em;
font-weight: 700;
padding: ${ll(14)};
text-align: center;
background-color: var(--green);
color: white;
border-radius: 100rem;
transition: opacity 0.4s;
&:hover {
  opacity: 0.75;
}
@media (max-width: 40rem){
  font-size: ${ll(16)};
line-height: normal;
letter-spacing: 0.03em;
font-weight: 700;
}
`

const Note = styled.div`
font-size: ${ll(9.350000381469727)};
line-height: 1.3636363080015006;
font-weight: 500;
margin-top: ${ll(14)};
@media (max-width: 40rem){
  font-size: ${ll(11)};
line-height: 1.3636363636363635;
font-weight: 500;
}
`

type ProductCardProps = {

}
export const ProductCard1: FunctionComponent<ProductCardProps> = () => {
  return <Outer>
    <Inner>
      <Animate>
        <HeadContainer>
          <RibbonContainer>
            <SvgRibbon />
          </RibbonContainer>
          <HeadLabel>
            ヘルシー & おいしいコース
          </HeadLabel>
        </HeadContainer>
      </Animate>
      <Title>
        <Subslabel>
          定期便
        </Subslabel>
        カレー<strong>2<small>種</small></strong> & パスタソース<strong>2<small>種</small></strong><br />
        合計<strong>12<small>食</small></strong>セット
      </Title>
      <Animate>
        <Container>
          <ImageContainer>
            <AssetImage src="ps1.png" />
          </ImageContainer>
          <CardContainer>
            <Card>
              <CardHead>
                初回限定価格
              </CardHead>
              <CardText1>
                1食あたり通常価格 / <del>1,150円<small>(税込)</small></del>が
              </CardText1>
              <CardInner>
                <OffCircle>
                  <small>約</small><strong>55</strong>%<br />
                  OFF
                </OffCircle>
                <CardPriceContainer>
                  <CardText2>
                    1食あたり
                  </CardText2>
                  <CardPrice>
                    <CardPriceNum>
                      509
                    </CardPriceNum>
                    <CardPriceUnit>
                      円
                    </CardPriceUnit>
                  </CardPrice>
                  <CardText3>
                    今なら送料無料
                  </CardText3>
                </CardPriceContainer>
              </CardInner>
              <CardBottom>
                <CardText4>
                  2回目以降も毎月お得！
                </CardText4>
                <CardText5>
                  1食あたり<strong>636</strong>円<small>(税込)</small>
                </CardText5>
                <CardText6>
                  <strong>約45%OFF</strong>の特別価格
                </CardText6>
              </CardBottom>
            </Card>
          </CardContainer>
          <ButtonContainer>
            <Button href="/Form/Order/CartList.aspx?ckbn=1&pid1=t-mc-ketomear4-3&vid1=t-mc-ketomear4-3&prdcnt1=1&fxpchs1=1">
              ご購入はこちら
            </Button>
            <Note>
              ※定期お届けサービスを継続された場合のお支払い総額は「初回：6,108円(税込)、２回目以降：7,635円(税込)」となります。<br />
              ※送料は当社負担<br />
              ※割引率は楽天/Amazonにて販売中の「まめとくらす グルテンフリー カレー & パスタ ソース 4種セット 4食セット」通常価格の1食あたり1,150円(税込)より計算
            </Note>
          </ButtonContainer>
        </Container>
      </Animate>
    </Inner>
  </Outer>
}
export const ProductCard2: FunctionComponent<ProductCardProps> = () => {
  return <Outer>
    <Inner>
      <Animate>
        <HeadContainer>
        </HeadContainer>
      </Animate>
      <Title>
        <Subslabel>
          定期便
        </Subslabel>
        カレー<strong>2<small>種</small></strong> 合計<strong>8<small>食</small></strong>セット
      </Title>
      <Animate>
        <Container>
          <ImageContainer>
            <AssetImage src="ps2.png" />
          </ImageContainer>
          <CardContainer>
            <Card>
              <CardHead>
                初回限定価格
              </CardHead>
              <CardText1>
                1食あたり通常価格 / <del>1,150円<small>(税込)</small></del>が
              </CardText1>
              <CardInner>
                <OffCircle>
                  <small>約</small><strong>45</strong>%<br />
                  OFF
                </OffCircle>
                <CardPriceContainer>
                  <CardText2>
                    1食あたり
                  </CardText2>
                  <CardPrice>
                    <CardPriceNum>
                      636
                    </CardPriceNum>
                    <CardPriceUnit>
                      円
                    </CardPriceUnit>
                  </CardPrice>
                  <CardText3>
                    今なら送料無料
                  </CardText3>
                </CardPriceContainer>
              </CardInner>
              <CardBottom>
                <CardText4>
                  2回目以降も毎月お得！
                </CardText4>
                <CardText5>
                  1食あたり<strong>795</strong>円<small>(税込)</small>
                </CardText5>
                <CardText6>
                  <strong>約31%OFF</strong>の特別価格
                </CardText6>
              </CardBottom>
            </Card>
          </CardContainer>
          <ButtonContainer>
            <Button href="/Form/Order/CartList.aspx?ckbn=1&pid1=t-mc-keto2-4&vid1=t-mc-keto2-4&prdcnt1=1&fxpchs1=1">
              ご購入はこちら
            </Button>
            <Note>
              ※定期お届けサービスを継続された場合のお支払い総額は「初回：5,086円(税込)、２回目以降：6,358円(税込)」となります。<br />
              ※送料は当社負担<br />
              ※割引率は楽天/Amazonにて販売中の「まめとくらす グルテンフリー カレー & パスタ ソース 4種セット 4食セット」通常価格の1食あたり1,150円(税込)より計算
            </Note>
          </ButtonContainer>
        </Container>
      </Animate>
    </Inner>
  </Outer>
}
export const ProductCard3: FunctionComponent<ProductCardProps> = () => {
  return <Outer>
    <Inner>
      <Animate>
        <HeadContainer>
        </HeadContainer>
      </Animate>
      <Title>
        <Subslabel>
          定期便
        </Subslabel>
        パスタソース<strong>2<small>種</small></strong> 合計<strong>8<small>食</small></strong>セット
      </Title>
      <Animate>
        <Container>
          <ImageContainer>
            <AssetImage src="ps3.png" />
          </ImageContainer>
          <CardContainer>
            <Card>
              <CardHead>
                初回限定価格
              </CardHead>
              <CardText1>
                1食あたり通常価格 / <del>1,150円<small>(税込)</small></del>が
              </CardText1>
              <CardInner>
                <OffCircle>
                  <small>約</small><strong>45</strong>%<br />
                  OFF
                </OffCircle>
                <CardPriceContainer>
                  <CardText2>
                    1食あたり
                  </CardText2>
                  <CardPrice>
                  <CardPriceNum>
                    636
                  </CardPriceNum>
                  <CardPriceUnit>
                    円
                  </CardPriceUnit>
                  </CardPrice>
                  <CardText3>
                    今なら送料無料
                  </CardText3>
                </CardPriceContainer>
              </CardInner>
              <CardBottom>
                <CardText4>
                  2回目以降も毎月お得！
                </CardText4>
                <CardText5>
                  1食あたり<strong>795</strong>円<small>(税込)</small>
                </CardText5>
                <CardText6>
                  <strong>約31%OFF</strong>の特別価格
                </CardText6>
              </CardBottom>
            </Card>
          </CardContainer>
          <ButtonContainer>
            <Button href="/Form/Order/CartList.aspx?ckbn=1&pid1=t-mc-mear2-4&vid1=t-mc-mear2-4&prdcnt1=1&fxpchs1=1">
              ご購入はこちら
            </Button>
            <Note>
              ※定期お届けサービスを継続された場合のお支払い総額は「初回：5,086円(税込)、２回目以降：6,358円(税込)」となります。<br />
              ※送料は当社負担<br />
              ※割引率は楽天/Amazonにて販売中の「まめとくらす グルテンフリー カレー & パスタ ソース 4種セット 4食セット」通常価格の1食あたり1,150円(税込)より計算
            </Note>
          </ButtonContainer>
        </Container>
      </Animate>
    </Inner>
  </Outer>
}