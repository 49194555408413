import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Page } from '../components/page'
import { TopSlider } from '../components/top-slider'
import { TopAboutSection } from '../sections/top-about-section'
import { TopPointSecton } from '../sections/top-point-secton'
import { TopRecommendSection } from '../sections/top-recommend-section'
import { TopProductsSection } from '../sections/top-products-section'
import { TopHowToSecton } from '../sections/top-how-to-secton'
import { TopOrderSection } from '../sections/top-order-section'
import { TopFaqSection } from '../sections/top-faq-section'
import { TopNewsSection } from '../sections/top-news-section'
import { RestoreScroll } from '../components/restore-scroll'

const Outer = styled.div`
  background-color: #fff;
`

type TopPageProps = {

}
export const TopPage: FunctionComponent<TopPageProps> = () => {
  return <Page>
    <RestoreScroll />
    <Outer>
      <TopSlider />
      <TopAboutSection />
      <TopPointSecton />
      <TopRecommendSection />
      <TopProductsSection />
      <TopHowToSecton />
      <TopOrderSection />
      <TopFaqSection />
      <TopNewsSection />
    </Outer>
  </Page>
}