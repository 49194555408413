import * as React from "react";
import type { SVGProps } from "react";
const SvgRibbon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={522}
    height={78}
    fill="none"
    viewBox="0 0 522 78"
    {...props}
  >
    <path
      fill="url(#91dda8b6b4835c051aee5fd3cd94f101fff65f33-a)"
      d="M459 12.75h62.9l-11.05 30.875L521.9 77.35H459z"
    />
    <path
      fill="url(#91dda8b6b4835c051aee5fd3cd94f101fff65f33-b)"
      d="M62.9 12.75H0l11.05 30.875L0 77.35h62.9z"
    />
    <path
      fill="url(#91dda8b6b4835c051aee5fd3cd94f101fff65f33-c)"
      d="M36.551 0h447.95v64.6H36.551z"
    />
    <path
      fill="#C88A0C"
      d="M459 64.6h26.35L459 77.35zM62.9 64.6H36.55L62.9 77.35z"
    />
    <defs>
      <linearGradient
        id="91dda8b6b4835c051aee5fd3cd94f101fff65f33-a"
        x1={459}
        x2={512.125}
        y1={45.05}
        y2={45.05}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DB9507" />
        <stop offset={1} stopColor="#F4A300" />
      </linearGradient>
      <linearGradient
        id="91dda8b6b4835c051aee5fd3cd94f101fff65f33-b"
        x1={62.9}
        x2={9.775}
        y1={45.05}
        y2={45.05}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DB9507" />
        <stop offset={1} stopColor="#F4A300" />
      </linearGradient>
      <linearGradient
        id="91dda8b6b4835c051aee5fd3cd94f101fff65f33-c"
        x1={36.551}
        x2={484.501}
        y1={32.3}
        y2={32.3}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4A300" />
        <stop offset={0.5} stopColor="#F1BE57" />
        <stop offset={1} stopColor="#F4A300" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgRibbon;
