import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { useExternalCss } from '../hooks/use-external-css'

const Outer = styled.div`
  padding-top: var(--header-height);

`

type PageProps = {
  children: ReactNode
}
export const Page: FunctionComponent<PageProps> = ({children}) => {
  return <Outer>
    {children}
  </Outer>
}