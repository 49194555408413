import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgVerticalLogo from '../generated/svg/vertical-logo'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AssetImage } from './image'
import { useBreakpoint } from '../hooks/use-break-point'
import { Autoplay, EffectFade } from 'swiper/modules'

const Outer = styled.div`
  width: 100vw;
  height: calc(100vh);
  margin-top: calc(var(--header-height) * -1);
  @media (max-width: 70rem){
    height: calc(100vh - var(--header-height));
    margin-top: 0;
  }
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: 3fr 7fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  height: 100%;
  @media (max-width: 70rem){
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 6fr;
  }
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    grid-template-rows: 2.5fr 5fr 2.5fr;
  }
`

const LogoContainer = styled.div`
  grid-area: 1 / 1;
  background-color: var(--orange);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  container-type: size;
  svg {
    display: block;
    height: auto;
    width: 70cqw;
    @media (max-width: 40rem){
      width: 50cqw;
    }
  }
`

const TextContainer = styled.div`
  grid-area: 2 / 1;
  background-color: var(--green);
display: flex;
align-items: center;
justify-content: center;
text-align: center;
  container-type: size;
@media (max-width: 70rem){
  grid-area: 1 / 2;
}
@media (max-width: 40rem){
  grid-area: 3 / 1;
}
`

const Text1 = styled.div`
  font-size: 7cqw;
  line-height: 1.7000000476837158;
  font-weight: 700;
  color: white;
  @media (max-width: 40rem){
    font-size: 4.5cqw;
    font-weight: 500;
    br:nth-of-type(odd){
      display: none;
    }
  }
`

const SwiperContainer = styled.div`
grid-area: 1 / 2 / span 2 / auto;
min-width: 0;
@media (max-width: 70rem){
  grid-area: 2 / 1 / auto / span 2;
}
@media (max-width: 40rem){
  grid-area: 2 / 1;
}
.swiper,
.swiper-wrapper,
.swiper-slide {
  width: 100%;
  height: 100%;
}
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const images = [
  {
    src: 'ts1.png',
    mobileStc: 'tsm1.png'
  },
  {
    src: 'ts2.png',
    mobileStc: 'tsm2.png'
  },
  {
    src: 'ts3.png',
    mobileStc: 'tsm3.png'
  }
]

type TopSliderProps = {

}
export const TopSlider: FunctionComponent<TopSliderProps> = () => {
  const isMobile = useBreakpoint('70rem')
  return <Outer id="top-slider">
    <Inner>
      <LogoContainer>
        <SvgVerticalLogo />
      </LogoContainer>
      <TextContainer>
        <Text1>
          グルテンフリー &<br />
          植物素材100%<br />
          豆と野菜のチカラで<br />
          健康的なくらしを
        </Text1>
      </TextContainer>
      <SwiperContainer>
        <Swiper
          modules={[
            EffectFade,
            Autoplay
          ]}
          effect="fade"
          speed={1000}
          autoplay={{
            delay: 4000
          }}
          loop
        >
          {images.map((image, i) => {
            return <SwiperSlide key={i}>
              <ImageContainer>
                <AssetImage src={isMobile ? image.mobileStc : image.src} />
              </ImageContainer>
            </SwiperSlide>
          })}
        </Swiper>
      </SwiperContainer>
    </Inner>

  </Outer>
}