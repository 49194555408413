import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { AssetImage } from '../components/image'
import SvgMame from '../generated/svg/mame'
import SvgTg1 from '../generated/svg/tg1'
import SvgTg2 from '../generated/svg/tg2'
import { ll } from '../utils/lcqw'
import { pageMaxWidth } from '../styles/page-max-width'
import { Dbr, Mbr } from '../components/br'
import { useBreakpoint } from '../hooks/use-break-point'
import { Animate } from '../components/animate'
import { slideinFromBottom, slideinFromLeft, slideinFromRight } from '../styles/keyframes'

const Outer = styled.div`

`

const Inner = styled.div`
${pageMaxWidth}
container-type: inline-size;
`

const HeadImg = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const ItemContainer = styled.div`
margin-top: ${ll(100)};
@media (max-width: 40rem){
  margin-top: ${ll(30)};
}
`

const Item = styled.div`
display: flex;
flex-direction: column;
align-items: center;
& + & {
  margin-top: ${ll(160)};
  @media (max-width: 40rem){
    margin-top: ${ll(100)};
  }
}
`

const NumContainer = styled.div`
position: relative;
width: fit-content;
`
const zoomIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    opacity: 1;
  }

`
const NumLabel = styled.div`
font-size: ${ll(17.80345916748047)};
line-height: 1.210526304512253;
letter-spacing: 0.04em;
font-weight: 800;
font-family: var(--en-font);
position: relative;
z-index: 1;
color: white;
text-align: center;
@media (max-width: 40rem){
  font-size: ${ll(9.797829627990723)};
line-height: 1.210526244068754;
letter-spacing: 0.04em;
font-weight: 800;
}
span {

  font-size: ${ll(27.1737003326416)};
line-height: 0.7931034555369978;
letter-spacing: 0.04em;
font-weight: 800;
display: block;
@media (max-width: 40rem){
  font-size: ${ll(14.954581260681152)};
line-height: 0.7931034438778357;
letter-spacing: 0.04em;
font-weight: 800;
}
}
  opacity: 0;
.animate & {
  animation: ${zoomIn} 1s 0.4s both cubic-bezier(.47,1.64,.41,.8);
}
`

const rollIn = keyframes`
  from {
    transform: scale(0.5) rotate(-360deg);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }

`
const NumBg = styled.div`
color: var(--green);
position: absolute;
top: 50%;
left: 50%;
translate: -50% -50%;
svg {
  display: block;
  height: ${ll(72)};
  width: auto;
  @media (max-width: 40rem){
    height: ${ll(43)};
  }
}
  opacity: 0;
.animate & {
  animation: ${rollIn} 1s 0.4s both cubic-bezier(.47,1.64,.41,.8);
}
`

const Title = styled.div`
font-size: ${ll(36)};
line-height: 1.0555555555555556;
letter-spacing: 0.04em;
font-weight: 700;
margin-top: ${ll(37)};
color: var(--green);
text-align: center;
@media (max-width: 40rem){
  font-size: ${ll(23)};
line-height: 1.3695652173913044;
letter-spacing: 0.02em;
font-weight: 700;
margin-top: ${ll(18)};
}
.animate & {
  animation: ${slideinFromBottom} 1s 0.4s both;
}
`

const HorizontalContainer1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${ll(57)};
  gap: ${ll(60)};
  position: relative;
  z-index: 1;
  @media (max-width: 40rem){
    gap: ${ll(18)};
    grid-template-columns: 1fr;
    margin-top: ${ll(21)};
  }
`

const HorizontalContainer1ImgContianer = styled.div`
opacity: 0;
@media (max-width: 40rem){
  order: -1;
}
  ${Item}:nth-child(odd) & {
    margin-left: calc(var(--side-margin) * -1);
    img {
      border-radius: 0 ${ll(10)} ${ll(10)} 0;
    }
  }
  ${Item}:nth-child(odd) .animate & {
    animation: ${slideinFromLeft} 1s 0.4s both;
  }
  ${Item}:nth-child(even) & {
    order: 1;
    margin-right: calc(var(--side-margin) * -1);
    img {
      border-radius: ${ll(10)} 0 0 ${ll(10)};
    }
  }
  ${Item}:nth-child(even) .animate & {
    animation: ${slideinFromRight} 1s 0.4s both;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const HorizontalContainer1TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  ${Item}:nth-child(odd) .animate & {
    animation: ${slideinFromRight} 1s 0.4s both;
  }
  ${Item}:nth-child(even) .animate & {
    animation: ${slideinFromLeft} 1s 0.4s both;
  }
`

const Text1 = styled.div`
font-size: ${ll(16)};
line-height: 1.75;
letter-spacing: 0.02em;
font-weight: 500;
@media (max-width: 40rem){
  font-size: ${ll(14)};
line-height: 1.5;
letter-spacing: -0.03em;
font-weight: 500;
}
  ${Item}:nth-child(2) & {
    padding-bottom: ${ll(60)};
  }
  ${Item}:nth-child(3) & {
    padding-bottom: ${ll(60)};
  }

  strong {
    font-weight: 700;
  }
`

const VegunImgContainer = styled.div`
  margin-block: ${ll(18)};
  @media (max-width: 40rem){
    margin-block: ${ll(22)};
    align-self: center;
  }
  img {
    display: block;
    height: ${ll(135)};
    width: auto;
    @media (max-width: 40rem){
      height: ${ll(119)};
    }
  }
`

const Container = styled.div`
  background-color: var(--beige);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${ll(56)} ${ll(34)};
  border-radius: ${ll(10)};
  margin-top: ${ll(50)};
  @media (max-width: 40rem){
    padding: ${ll(45)} ${ll(16)};
  margin-top: ${ll(21)};
  }
  ${Item}:nth-child(2) & {
    margin-top: ${ll(-50)};
    @media (max-width: 40rem){
      margin-top: ${ll(21)};
    }
  }
  ${Item}:nth-child(3) & {
    margin-top: ${ll(-30)};
    @media (max-width: 40rem){
      margin-top: ${ll(21)};
    }
  }
  opacity: 0;
  .animate > & {
    animation: ${slideinFromBottom} 1s 0.4s both;
  }
`

const ContainerInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${ll(60)};
  margin-top: ${ll(32)};
  @media (max-width: 40rem){
    gap: ${ll(22)};
  margin-top: 0;
  grid-template-columns: 1fr;
  }
`

const ContainerTitle = styled.div`
font-size: ${ll(25)};
line-height: 1.72;
letter-spacing: 0.03em;
font-weight: 700;
text-align: center;
@media (max-width: 40rem){
  font-size: ${ll(19)};
line-height: 1.3157894736842106;
letter-spacing: 0.03em;
font-weight: 700;
}
${ContainerInner} & {
  text-align: left;
  @media (max-width: 40rem){
    text-align: center;
  }
}
strong {
  color: var(--green);
}
`


const GraphContainer = styled.div`
  position: relative;
  @media (max-width: 40rem){
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  svg {
    display: block;
    height: auto;
  }
  ${Item}:nth-child(1) & {
    svg {
      width: ${ll(365)};
      @media (max-width: 40rem){
        width: ${ll(258)};
      }
    }
  }
  ${Item}:nth-child(2) & {
    svg {
      width: 100%;
      @media (max-width: 40rem){
        width: ${ll(282)};
      }
    }
  }
  ${Item}:nth-child(3) & {
    img {
      display: block;
      width: 100%;
      height: auto;
      @media (max-width: 40rem){
        width: ${ll(282)};
      }
    }
  }
`

const GraphLabel = styled.div`
font-size: ${ll(16.285715103149414)};
line-height: 1.666666549548774;
font-weight: 700;
padding: ${ll(7)} ${ll(20)};
border: 1px solid currentColor;
background-color: white;
border-radius: 100rem;
text-align: center;
width: fit-content;
align-self: center;
bottom: 100%;
position: absolute;
left: 0;
right: 0;
margin-inline: auto;
margin-bottom: ${ll(12)};
@media (max-width: 40rem){
  position: static;
  font-size: ${ll(13)};
line-height: 1.535405525794396;
font-weight: 700;
padding: ${ll(5)} ${ll(23)};
margin-bottom: ${ll(10)};
}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 40rem){
    
  }
`

const Label = styled.div`
font-size: ${ll(16)};
line-height: 1.4375;
font-weight: 700;
width: fit-content;
padding: ${ll(9)} ${ll(18)};
border-radius: 100rem;
@media (max-width: 40rem){
  font-size: ${ll(14)};
line-height: 0.8214285714285714;
font-weight: 700;
padding: ${ll(9)} ${ll(21)};
align-self: center;
}
&:not(:first-child){
  margin-top: ${ll(27)};
  @media (max-width: 40rem){
    margin-top: ${ll(30)};
  }
}
`

const Item1Text = styled.div`
font-size: ${ll(16)};
line-height: 1.4375;
font-weight: 500;
margin-top: ${ll(8)};
@media (max-width: 40rem){
  font-size: ${ll(14)};
line-height: 1.5;
letter-spacing: -0.02em;
font-weight: 500;
margin-top: ${ll(5)};
text-align: center;
}
`

const Item2Text = styled.div`
font-size: ${ll(16)};
line-height: 1.875;
font-weight: 500;
margin-top: ${ll(33)};
@media (max-width: 40rem){
  font-size: ${ll(14)};
line-height: 1.5;
letter-spacing: -0.04em;
font-weight: 500;
margin-top: ${ll(22)};
}
strong {
  font-weight: 700;
}
`

const Item3Text = styled.div`
font-size: ${ll(14)};
line-height: 1.5714285714285714;
font-weight: 500;
margin-top: ${ll(34)};
@media (max-width: 40rem){
  font-size: ${ll(14)};
line-height: 1.7000000476837158;
font-weight: 500;
}
`

const Note1 = styled.div`
font-size: ${ll(10)};
line-height: 1.6;
font-weight: 300;
width: 100%;
text-align: right;
margin-top: ${ll(16)};
@media (max-width: 40rem){
  font-size: ${ll(10)};
line-height: 1.3;
letter-spacing: 0.04em;
text-align: left;
}
${Container} & {
  text-align: left;
  margin-top: auto;
  @media (max-width: 40rem){
    margin-top: ${ll(22)};
  }
}
`


type TopPointSectonProps = {

}
export const TopPointSecton: FunctionComponent<TopPointSectonProps> = () => {
  const isMobile = useBreakpoint('40rem')
  return <Outer>
    <HeadImg>
      <AssetImage src={isMobile ? 'tm1.png' : 't1.png'} />
    </HeadImg>
    <Inner>
      <ItemContainer>
        <Item>
          <Animate>
            <NumContainer>
              <NumBg>
                <SvgMame />
              </NumBg>
              <NumLabel>
                POINT
                <span>01</span>
              </NumLabel>
            </NumContainer>
            <Title>
              3つの品質保障<Mbr />「食の安心安全」
            </Title>
          </Animate>
          <HorizontalContainer1>
            <Animate>
              <HorizontalContainer1ImgContianer>
                <AssetImage src="t2.png" />
              </HorizontalContainer1ImgContianer>
              <HorizontalContainer1TextContainer>
                <Text1>
                  『まめとくらす』は、すべてのメニューで<br />
                  <strong>
                    動物性原料不使用、グルテンフリー(小麦不使用)<sup>※1</sup>、<br />
                    添加物に頼らない味づくり<sup>※2</sup>と 3つの品質保証を実現。
                  </strong>
                </Text1>
                <VegunImgContainer>
                  <AssetImage src="vegan-logo.png" />
                </VegunImgContainer>
                <Text1>
                  動物に由来する物を含まない事を証明するヴィーガン認証を取得済みです。<sup>※1</sup><br />
                  <br />
                  目指したのは年代や性別問わず、誰でも安心して続けられる食事です。ヴィーガンやグルテンフリーのライフスタイルではない人にとっても、食べる価値があるものにしたいと考え、カラダによく、おいしい品質に仕上げました。
                </Text1>
              </HorizontalContainer1TextContainer>
            </Animate>
          </HorizontalContainer1>
          <Animate>
            <Container>
              <ContainerTitle>
                <strong>安心安全のための</strong><Mbr />3つの品質保証
              </ContainerTitle>
              <ContainerInner>
                <GraphContainer>
                  <SvgTg1 />
                </GraphContainer>
                <TextContainer>
                  <Label style={{backgroundColor: '#FDF2DC'}}>
                    動物性原料不使用
                  </Label>
                  <Item1Text>
                    カロリーや脂質を抑える事ができます。<br />
                    ヴィーガンの方もお召し上がりいただけます。<sup>※1</sup>
                  </Item1Text>
                  <Label style={{backgroundColor: '#FADED4'}}>
                    グルテンフリー(小麦不使用)<sup>※1</sup>
                  </Label>
                  <Item1Text>
                    体の調子を整える効果や美容効果があります。<br />
                    置き換えダイエットにもご活用いただけます。<sup>※3</sup>
                  </Item1Text>
                  <Label style={{backgroundColor: '#EBF4D8'}}>
                    添加物に頼らない味づくり<sup>※2</sup>
                  </Label>
                  <Item1Text>
                    添加物に頼らない調理方法で、<br />
                    素材本来のおいしさを最大限に引き立てます。
                  </Item1Text>
                </TextContainer>
              </ContainerInner>
            </Container>
          </Animate>
          <Note1>
            ※1 同じ製造設備で、動物由来原料・小麦を含む製品製造も行っております。<br />
            ※2「味覚に影響する食品添加物（甘味料、酸味料、調味料、苦味料、香辛料抽出物、香料）」を使用していません。<br />
            ※3 出典:組織におけるグルテンフリー表示に係る調査報告書。
          </Note1>
        </Item>
        <Item>
          <Animate>
            <NumContainer>
              <NumBg>
                <SvgMame />
              </NumBg>
              <NumLabel>
                POINT
                <span>02</span>
              </NumLabel>
            </NumContainer>
            <Title>
              豆と野菜のチカラで<Mbr />「最適な栄養バランス」
            </Title>
          </Animate>
          <HorizontalContainer1>
            <Animate>
              <HorizontalContainer1ImgContianer>
                <AssetImage src="t3.png" />
              </HorizontalContainer1ImgContianer>
              <HorizontalContainer1TextContainer>
                <Text1>
                  植物性タンパク質の貴重な源となる豆と、<Dbr />
                  ビタミンやミネラルが豊富な野菜の組み合わせにより、<Dbr />
                  日々摂取しづらい多彩な栄養素が含まれています。<br />
                  <br />
                  食物繊維も豊富で、身体の機能をサポートする腸活にもぴったり。体に必要なエネルギー源を補いながら、脂質や糖質を抑えた栄養バランスで、現代人の美容と健康を支えます。
                </Text1>
              </HorizontalContainer1TextContainer>
            </Animate>
          </HorizontalContainer1>
          <Animate>
            <Container>
              <ContainerInner>
                <GraphContainer>
                  <GraphLabel>キーマカレーによる栄養成分の比較</GraphLabel>
                  <SvgTg2 />
                </GraphContainer>
                <TextContainer>
                  <ContainerTitle>
                    高たんぱく＆豊富な食物繊維<br />
                    <strong>栄養成分にメリハリを</strong>
                  </ContainerTitle>
                  <Item2Text>
                    「香り広がる11種の本格スパイスキーマカレー」を<br />
                    手作りのキーマカレーと比べてみると、<br />
                    <strong>タンパク質や食物繊維は多く、グルテンフリー<sup>※2</sup>で低糖質。<br />
                    脂質やエネルギーは抑えめで、健康・美容を意識した<br />
                    栄養バランス</strong>となっています。
                  </Item2Text>
                  <Note1>
                    ※1 日本食品標準成分表（八訂）増補2023年より、一般的な手作りのキーマカレーに使われる材料を用いて150gあたりで算出。<br />
                    ※2 同じ製造設備で、小麦を含む製品製造もおこなっております
                  </Note1>
                </TextContainer>
              </ContainerInner>
            </Container>
          </Animate>
        </Item>
        <Item>
          <Animate>
            <NumContainer>
              <NumBg>
                <SvgMame />
              </NumBg>
              <NumLabel>
                POINT
                <span>03</span>
              </NumLabel>
            </NumContainer>
            <Title>
              カラダが喜ぶ<Mbr />自然なおいしさを追求
            </Title>
          </Animate>
          <HorizontalContainer1>
            <Animate>
              <HorizontalContainer1ImgContianer>
                <AssetImage src="t3-1.png" />
              </HorizontalContainer1ImgContianer>
              <HorizontalContainer1TextContainer>
                <Text1>
                  <strong>
                    ヘルシーだけど、旨みもコクもあってちゃんとおいしい！<br />
                    何度も食べたくなるようなおいしさを実現したのが、<br />
                    『まめとくらす』です。<br />
                  </strong>
                  <br />
                  カラダに必要な栄養素を食事でカンタンに摂るのは難しいもの。まめとくらすの商品は食物繊維がたくさん含まれている上に、豆と野菜の持つ素材の旨味がギュッと詰め込まれています。<br />
                  <br />
                  肉や小麦を使用しないからこそ生まれた、まめとくらすならではのおいしさをご賞味ください。
                </Text1>
              </HorizontalContainer1TextContainer>
            </Animate>
          </HorizontalContainer1>
          <Animate>
            <Container>
              <ContainerInner>
                <GraphContainer>
                  <AssetImage src="t4.png" />
                </GraphContainer>
                <TextContainer>
                  <ContainerTitle>
                    代表の想い<br />
                    <strong>「大切な人におすすめできるものを」</strong>
                  </ContainerTitle>
                  <Item3Text>
                    私が子どものころは、みんな「牛乳を飲めば大きくなる」と信じていました。私も背を伸ばしたくて毎日飲み続けていたのですが、よくお腹を壊していました。<br />
                    後に、牛乳アレルギーだったことを知りました。<br />
                    <br />
                    今では多くの人が食物アレルギーを持っていますが、その中には、私のように知らないうちに誤った食生活を送っている人も少なからずいるはずです。そんな方でも、安心して食べられるものがあったらどんなに幸せだろう、と思うようになりました。
                  </Item3Text>
                </TextContainer>
              </ContainerInner>
              <Item3Text>
                父の病気がきっかけでより食に対して意識するようになり、なるべく添加物や動物性食品を摂らないようにしたり、内臓に負担のかかるお酒も辞める等、食生活を意識的に変えていきました。その結果、お腹はもちろん壊さなくなりましたし、健康的に痩せて顔色も常に良くなり、楽しく生きる活力が湧きました。<br />
                <br />
                身体の不調は毎日の食事から改善できます。<br />
                心も身体も整え、より豊かな生活を送るために、「まめとくらす」を選んでいただけたら幸いです。
              </Item3Text>
            </Container>
          </Animate>
        </Item>
      </ItemContainer>
    </Inner>
  </Outer>
}