import { FunctionComponent, useEffect } from 'react'
import { throttle } from 'throttle-debounce'
import { isNotNullish } from '../utils/type-check'
import { querySelectorWithShadow } from '../utils/query-selector-with-shadow'

type RestoreScrollProps = {

}
export const RestoreScroll: FunctionComponent<RestoreScrollProps> = () => {
  useEffect(() => {
    const listener = throttle(200, () => {
      history.replaceState({...history.state, scrollY: window.scrollY}, "")
    }, {})
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])
  useEffect(() => {
    const listener = async () => {
      const scrollY = history.state?.scrollY
      if(location.hash){
        document.documentElement.style.opacity = '0'
      }
      setTimeout(async () => {
        await Promise.all(Array.from(document.querySelectorAll('img')).map(img => new Promise<void>(res => {
          if(img.complete){
            res()
          } else {
            img.addEventListener('load', () => res())
          }
        })))
        if(isNotNullish(scrollY)){
          window.scrollTo({top: scrollY ?? 0})
        } else {
          console.log(location.hash)
          if(location.hash){
            querySelectorWithShadow(location.hash)?.scrollIntoView()
          }
        }
        setTimeout(() => {
          document.documentElement.style.opacity = ''
          console.log(document.documentElement.style.opacity)
        }, 1)
      }, 500)
    }
    window.addEventListener('popstate', listener)
    listener()
    return () => {
      window.removeEventListener('popstate', listener)
    }
  }, [])
  useEffect(() => {
    const listener = () => {
      console.log('hashchange', querySelectorWithShadow(location.hash))
      if(location.hash){
        if(location.hash === '#'){
          window.scrollTo({top: 0, behavior: 'smooth'})
        } else {
          querySelectorWithShadow(location.hash)?.scrollIntoView({behavior: 'smooth'})
        }
      }
    }
    window.addEventListener('hashchange', listener)
    return () => {
      window.removeEventListener('hashchange', listener)
    }
  })
  return null
}