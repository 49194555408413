import * as React from "react";
import type { SVGProps } from "react";
const SvgGrayChilliPepper = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={30}
    fill="none"
    viewBox="0 0 15 30"
    {...props}
  >
    <g
      fill="#B2B2B2"
      clipPath="url(#0a2871349bf3152b0c67f24e88060e629a77df57-a)"
    >
      <path d="M8.962 6.745C6.128 10.79 10.22 21.925 1.12 28.671a3 3 0 0 0-.179.143c-3.808 3.302 4.999-.985 7.608-4.334 3.52-3.603 8.086-18.616 2.795-18.999-1.032-.074-1.83.476-2.382 1.264" />
      <path d="M9.247 6.023c.913-.9 1.949-1.17 3.15-.52.312.17 1.111.917 1.027.585-.218-.853-1.281-1.34-1.678-1.463.221-1.986.823-4.396 3.14-4.194.408-.23-.367-.499-.822-.415-2.001.367-2.721 2.68-2.948 4.526-.413.08-.736.203-1.171.45-.897.51-1.392 1.622-.698 1.03z" />
    </g>
    <defs>
      <clipPath id="0a2871349bf3152b0c67f24e88060e629a77df57-a">
        <path fill="#fff" d="M0 0h15v30H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGrayChilliPepper;
