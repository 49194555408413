import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { Animate } from '../components/animate'
import { AssetImage } from '../components/image'
import { pageMaxWidth } from '../styles/page-max-width'
import { ll } from '../utils/lcqw'
import { slideinFromBottom } from '../styles/keyframes'
import { count } from '../styles/count'

const Outer = styled.div`
  background-color: var(--beige);
`

const Inner = styled.div`
  ${pageMaxWidth}
  &::before {
    content: '';
    display: block;
    padding-top: ${ll(100)};
    @media (max-width: 40rem){
      padding-top: ${ll(53)};
    }
  }
  &::after {
    content: '';
    display: block;
    padding-bottom: ${ll(137)};
    @media (max-width: 40rem){
      padding-bottom: ${ll(60)};
    }
  }
`

const Text1 = styled.div`
font-size: ${ll(20)};
line-height: 1.9;
letter-spacing: 0.04em;
font-weight: 500;
text-align: center;
@media (max-width: 40rem){
  font-size: ${ll(14)};
line-height: 1.7857142857142858;
letter-spacing: 0.04em;
font-weight: 500;
}
  opacity: 0;
.animate > & {
  animation: ${slideinFromBottom} 1s 0.4s both;
}
`
const expand = keyframes`
  from {
    gap: 0;
  }
`
const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${ll(12)};
  justify-content: center;
  padding-top: ${ll(47)};
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 40rem){
    padding-top: ${ll(20)};
  }
  .animate & {
    animation: ${expand} 1s 0.4s both;
  }
`
const Item = styled.div`
  img {
    display: block;
    height: ${ll(117)};
    width: auto;
    @media (max-width: 40rem){
      height: ${ll(65)};
    }
  }
  ${count}
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .animate & {
    animation: ${slideinFromBottom} 1s 0.4s both;
  }
`

const Mbr = styled.br`
  display: none;
  @media (max-width: 40rem){
    display: inline;
  }
`

type TopAboutSectionProps = {

}
export const TopAboutSection: FunctionComponent<TopAboutSectionProps> = () => {
  return <Outer id="about">
    <Inner>
      <Animate>
        <Text1>
          まめとくらすは、<br />
          「カラダにいい食生活と心からの笑顔を<Mbr />世界中へ届ける」を理念に、<br />
          豆と野菜のチカラで「おいしい」と<Mbr />「ヘルシー」の両立を目指しています。<br />
          <br />
          健康なカラダは毎日の食事からつくられます。<br />
          仕事や育児などで忙しく、<br />
          バランスの良い食生活を送ることが難しい<Mbr />全ての方に食べてほしい。<br />
          <br />
          古来より健康を培ってきた豆と野菜のチカラで、<br />
          今を生きる人々の明日のために、<Mbr />「未来を創る新しい食」を提案していきます。<br />
        </Text1>
      </Animate>
      <Animate>
        <List>
          <Item>
            <AssetImage src="d1.png" />
          </Item>
          <Item>
            <AssetImage src="d2.png" />
          </Item>
          <Item>
            <AssetImage src="d3.png" />
          </Item>
          <Item>
            <AssetImage src="d4.png" />
          </Item>
        </List>
      </Animate>
    </Inner>
  </Outer>
}