import * as React from "react";
import type { SVGProps } from "react";
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={53}
    height={52}
    fill="none"
    viewBox="0 0 53 52"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#FAF7F0"
      strokeWidth={1.5}
      d="M7.809 21.926h29.088v29.088H7.809z"
    />
    <path
      fill="#28561C"
      d="M30.135 15.723c-3.928 4.273-7.749 9.2-11.679 15.061-2.905-2.95-12.357-10.165-16.705-10.165-.6 0-1.105.134-1.501.4a.56.56 0 0 0-.246.538c.108.854.78 1.507 1.374 2.082.21.204.409.397.564.581l.02.023.022.021c6.54 6.205 11.781 13.068 15.58 20.4a2.254 2.254 0 0 0 4.042-.081C33.21 19.757 44.964 8.872 49.983 4.224 52.125 2.242 53.011 1.42 52.937.59a.56.56 0 0 0-.331-.464A1.6 1.6 0 0 0 51.944 0c-1.619 0-4.907 1.823-7.38 3.352l-.008.005a67 67 0 0 0-7.589 5.705 84 84 0 0 0-6.832 6.661"
    />
  </svg>
);
export default SvgCheck;
