import * as React from "react";
import type { SVGProps } from "react";
const SvgMame = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={82}
    height={72}
    fill="none"
    viewBox="0 0 82 72"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M5.08 17.721C12.483 5.414 27.607-2.888 40.74.941c9.377 2.735 9.652 7.16 14.778 9.628s9.409.235 17.128 5.723C83.45 23.98 84.04 39.844 76.638 52.154 65.75 70.252 40.906 77.215 21.145 67.708 1.385 58.2-5.809 35.819 5.08 17.72"
    />
  </svg>
);
export default SvgMame;
