import * as React from "react";
import type { SVGProps } from "react";
const SvgArc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1366}
    height={99}
    fill="none"
    viewBox="0 0 1366 99"
    {...props}
  >
    <path
      fill="currentcolor"
      fillRule="evenodd"
      d="M0 .275V99h1366V6.002C1224.14 61.9 974.4 99 690 99 395.996 99 139.035 59.352 0 .275"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArc;
