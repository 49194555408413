import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { AssetImage } from '../components/image'
import SvgChilliPepper from '../generated/svg/chilli-pepper'
import SvgGrayChilliPepper from '../generated/svg/gray-chilli-pepper'
import { ll } from '../utils/lcqw'
import { pageMaxWidth } from '../styles/page-max-width'
import { Animate } from '../components/animate'
import { slideinFromLeft, slideinFromRight } from '../styles/keyframes'
import { Link } from '../components/link'

const Outer = styled.div`
  container-type: inline-size;
  ${pageMaxWidth}
`

const Inner = styled.div`
  margin-inline: calc(var(--side-margin) * -1);
`

const List = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
@media (max-width: 40rem){
  grid-template-columns: 1fr;
}

`

const Item = styled.div`
display: grid;
grid-template-columns: subgrid;
grid-column: 1 / -1;
  background-color: #FBECCC;
`

const ImageContainer = styled(Link)`
  opacity: 0;
  display: block;
  overflow: clip;
  ${Item}:nth-child(odd) & {
    .animate > & {
      animation: ${slideinFromRight} 1s 0.4s both;
    }
    order: 1;
    @media (max-width: 40rem){
      order: 0
    }
  }
  ${Item}:nth-child(odd) .animate & {
    animation: ${slideinFromRight} 1s 0.4s both;
  }
  ${Item}:nth-child(even) .animate & {
    animation: ${slideinFromLeft} 1s 0.4s both;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.4s;
    @media (max-width: 40rem){
      height: ${ll(375)};
    }
  }
  &:hover img {
    transform: scale(1.025);
  }
`

const TextContainer = styled.div`
  width: ${ll(420)};
  align-self: center;
  justify-self: center;
  opacity: 0;
  ${Item}:nth-child(odd) .animate & {
    animation: ${slideinFromLeft} 1s 0.4s both;
  }
  ${Item}:nth-child(even) .animate & {
    animation: ${slideinFromRight} 1s 0.4s both;
  }
  @media (max-width: 40rem){
    width: 100%;
    padding-inline: var(--page-margin);
    padding-top: ${ll(36)};
    padding-bottom: ${ll(40)};
    box-sizing: border-box;
  }
`

const Name = styled(Link)`
display: block;
font-size: ${ll(26)};
line-height: 1.6538461538461537;
letter-spacing: 0.04em;
font-weight: 700;
@media (max-width: 40rem){
  font-size: ${ll(22)};
line-height: 1.3409090909090908;
font-weight: 700;
}
`

const HotContainer = styled.div`
  display: flex;
margin-top: ${ll(13)};
@media (max-width: 40rem){
  
margin-top: ${ll(16)};
}
`

const HotLabel = styled.div`
font-size: ${ll(15)};
line-height: normal;
font-weight: 700;
padding: ${ll(8)} ${ll(12)};
background: var(--brown);
color: white;
border-radius: ${ll(4)} 0 0 ${ll(4)};
place-content: center;
@media (max-width: 40rem){
  font-size: ${ll(12.828947067260742)};
line-height: normal;
font-weight: 700;
padding: ${ll(6)} ${ll(10)};
}
`

const HotList = styled.div`
  display: flex;
  gap: ${ll(2)};
  padding: ${ll(4)} ${ll(17)};
  background-color: white;
border-radius: 0 ${ll(4)} ${ll(4)} 0;
  @media (max-width: 40rem){
  padding: ${ll(3)} ${ll(14)};
    
  }
  svg {
    display: block;
    height: ${ll(30)};
    width: auto;
    @media (max-width: 40rem){
      
      height: ${ll(26)};
    }
  }
`

const Text = styled.div`
font-size: ${ll(16)};
line-height: 1.5;
letter-spacing: 0.01em;
font-weight: 500;
margin-top: ${ll(29)};
@media (max-width: 40rem){
  font-size: ${ll(14)};
line-height: 1.4285714285714286;
letter-spacing: 0.02em;
font-weight: 500;
margin-top: ${ll(24)};
br {
  display: none;
}
}
`

const Button = styled(Link)`
display: block;
font-size: ${ll(18)};
line-height: normal;
letter-spacing: 0.03em;
font-weight: 700;
margin-top: ${ll(30)};
padding: ${ll(18)};
background-color: var(--brown);
color: white;
text-align: center;
border-radius: 100rem;
transition: opacity 0.4s;
&:hover {
  opacity: 0.75;
}
@media (max-width: 40rem){
  font-size: ${ll(16)};
line-height: normal;
letter-spacing: 0.03em;
font-weight: 700;
padding: ${ll(14)};
}
`

type TopProductsSectionProps = {

}
export const TopProductsSection: FunctionComponent<TopProductsSectionProps> = () => {
  return <Outer id="products">
    <Inner>
      <List>
        <Item>
          <Animate>
            <ImageContainer href="/Landing/Formlp/og01.aspx?advc=og01">
              <AssetImage src="p1.png" />
            </ImageContainer>
            <TextContainer>
              <Name href="/Landing/Formlp/og01.aspx?advc=og01">
                香り広がる11種の<br />
                本格スパイスキーマカレー
              </Name>
              <HotContainer>
                <HotLabel>
                  辛さ
                </HotLabel>
                <HotList>
                  <SvgChilliPepper />
                  <SvgChilliPepper />
                  <SvgChilliPepper />
                </HotList>
              </HotContainer>
              <Text>
                異なる食感で噛み応え抜群。<br />
                後を引く辛さがクセになるキーマカレー。<br />
                豊かな香りと奥深い味わいを放つルウは、<br />
                厳選された11種のスパイスによるものです。<br />
                たくさんの野菜を使って旨味とコクが溶け込んだ、<br />
                スパイシーな１品。<br />
                大豆ミートのジューシーな食感をご堪能ください。
              </Text>
              <Button href="/Landing/Formlp/og01.aspx?advc=og01">
                詳細はこちら
              </Button>
            </TextContainer>
          </Animate>
        </Item>
        <Item>
          <Animate>
            <ImageContainer href="/Landing/Formlp/og01.aspx?advc=og01">
              <AssetImage src="p2.png" />
            </ImageContainer>
            <TextContainer>
              <Name href="/Landing/Formlp/og01.aspx?advc=og01">
                トマトをじっくり煮込んだ旨味<br />
                トマトキーマカレー
              </Name>
              <HotContainer>
                <HotLabel>
                  辛さ
                </HotLabel>
                <HotList>
                  <SvgChilliPepper />
                  <SvgGrayChilliPepper />
                  <SvgGrayChilliPepper />
                </HotList>
              </HotContainer>
              <Text>
                裏ごしたトマトがなめらかな口当たりの、<br />
                トマトキーマカレー。<br />
                じっくり煮込んだトマトのやわらかな酸味と、<br />
                凝縮された野菜の旨味が、味に深みを出しています。<br />
                時間と手間をかけて作る本格派のルウを、<br />
                もっと身近な存在に。何度でも食べたくなる、<br />
                辛すぎないマイルドな味わいです。
              </Text>
              <Button href="/Landing/Formlp/og01.aspx?advc=og01">
                詳細はこちら
              </Button>
            </TextContainer>
          </Animate>
        </Item>
        <Item>
          <Animate>
            <ImageContainer href="/Landing/Formlp/og01.aspx?advc=og01">
              <AssetImage src="p3.png" />
            </ImageContainer>
            <TextContainer>
              <Name href="/Landing/Formlp/og01.aspx?advc=og01">
                たっぷりトマトと旨味広がる野菜<br />
                ミートソース
              </Name>
              <HotContainer>
                <HotLabel>
                  辛さ
                </HotLabel>
                <HotList>
                  <SvgGrayChilliPepper />
                  <SvgGrayChilliPepper />
                  <SvgGrayChilliPepper />
                </HotList>
              </HotContainer>
              <Text>
                たっぷりトマトの爽やかな酸味と、<br />
                玉ねぎの甘みが際立つミートソース。<br />
                じっくりコトコト煮込んで引き出される野菜の旨味も、<br />
                しっかり堪能できます。<br />
                大豆ミートと野菜のゴロゴロとした食感、<br />
                グルテンフリーで実現したとろみ。<br />
                その濃厚な味わいを、パスタに絡めてお楽しみください。
              </Text>
              <Button href="/Landing/Formlp/og01.aspx?advc=og01">
                詳細はこちら
              </Button>
            </TextContainer>
          </Animate>
        </Item>
        <Item>
          <Animate>
            <ImageContainer href="/Landing/Formlp/og01.aspx?advc=og01">
              <AssetImage src="p4.png" />
            </ImageContainer>
            <TextContainer>
              <Name href="/Landing/Formlp/og01.aspx?advc=og01">
                ガーリック香る<br />
                濃厚トマトアラビアータ
              </Name>
              <HotContainer>
                <HotLabel>
                  辛さ
                </HotLabel>
                <HotList>
                  <SvgChilliPepper />
                  <SvgChilliPepper />
                  <SvgChilliPepper />
                </HotList>
              </HotContainer>
              <Text>
                ピリッとした辛みがきいた、<br />
                濃厚なトマトのアラビアータ。<br />
                ガーリックの香りとトマトのコクが広がる<br />
                深みのある1品です。<br />
                濃厚なトマトとワインの軽さで、<br />
                食べたら止まらない美味しさ。<br />
                口の中いっぱいに香りと味わい深さが広がり、<br />
                ペンネとの相性も抜群です。
              </Text>
              <Button href="/Landing/Formlp/og01.aspx?advc=og01">
                詳細はこちら
              </Button>
            </TextContainer>
          </Animate>
        </Item>
      </List>
    </Inner>
  </Outer>
}