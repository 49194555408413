import React, { ComponentProps } from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Picture = styled.picture`
  display: contents;
`

const Img = styled.img`
  display: block;
`

export const resolveAssetImageUrl = (filename: string) => {
  return `${site_path_root}Contents/images/${filename}`
}

export type ImageProps = {
  src: string
} & ComponentProps<'img'>
export const Image: FunctionComponent<ImageProps> = (props) => {
  return <Picture>
    <Img {...props} src={`${props.src.replace(/\.[^.]*$/, '')}.webp`}/>
  </Picture>
}

export const AssetImage: FunctionComponent<ImageProps> = (props) => {
  return <Picture>
    <Img {...props} src={resolveAssetImageUrl(props.src)}/>
  </Picture>
}