import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-head'
import SvgHi1 from '../generated/svg/hi1'
import { Swiper, SwiperSlide } from 'swiper/react'
import SvgRi1 from '../generated/svg/ri1'
import SvgRi2 from '../generated/svg/ri2'
import { ll } from '../utils/lcqw'
import SvgHi2 from '../generated/svg/hi2'
import SvgHi3 from '../generated/svg/hi3'
import { pageMaxWidth } from '../styles/page-max-width'
import { useBreakpoint } from '../hooks/use-break-point'
import { Dbr, Mbr } from '../components/br'
import { Animate } from '../components/animate'
import { count } from '../styles/count'
import { slideinFromBottom } from '../styles/keyframes'

const Outer = styled.div`
  ${pageMaxWidth}
  container-type: inline-size;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${ll(120)};
  @media (max-width: 40rem){
    padding-bottom: ${ll(70)};
  }
`

const List = styled.div`
  width: 100%;
  .swiper {
    overflow-x: visible;
    overflow-y: clip;
  }
  .swiper-wrapper {
    gap: ${ll(32)};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 40rem){
      gap: 0;
    }
  }
  .swiper-slide {
    width: auto;
    ${count}
  }
`

const Item = styled.div`
  @media (max-width: 40rem){
    width: ${ll(295)};
    margin-inline: ${ll(10)};
  }
  opacity: 0;
  .animate & {
    animation: ${slideinFromBottom} 1s calc(0.2s * var(--count)) both;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${ll(53)};
  background: var(--beige);
  border-radius: ${ll(10)};
  @media (max-width: 40rem){
    
  border-radius: ${ll(7)};
  padding: ${ll(50)};
  }
  svg {
    display: block;
    width: auto;
    height: ${ll(197)};
  }
`

const Num = styled.div`
  font-size: ${ll(40)};
line-height: normal;
letter-spacing: 0.04em;
font-weight: 800;
font-family: var(--en-font);
color: var(--green);
text-align: center;
margin-top: ${ll(10)};
@media (max-width: 40rem){
  font-size: ${ll(30)};
line-height: normal;
letter-spacing: 0.04em;
font-weight: 800;
margin-top: ${ll(2)};
}
`

const Text = styled.div`
  font-size: ${ll(15)};
line-height: normal;
letter-spacing: 0.02em;
font-weight: 700;
text-align: center;
margin-top: ${ll(5)};
@media (max-width: 40rem){
  font-size: ${ll(14)};
line-height: 1.5;
letter-spacing: 0.02em;
font-weight: 700;
margin-top: ${ll(2)};
}
small {
  font-size: ${ll(12)};
line-height: 2.5;
letter-spacing: -0.02em;
font-weight: 500;
@media (max-width: 40rem){
  font-size: ${ll(10)};
line-height: 2.5;
letter-spacing: 0.02em;
font-weight: 500;
}
}
`

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  border: 1px dotted currentColor;
  border-radius: ${ll(10)};
  margin-top: ${ll(48)};
  padding: ${ll(41)} ${ll(40)};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 40rem){
    
margin-top: ${ll(18)};
  padding: ${ll(39)} ${ll(25)};
  }
  opacity: 0;
.animate > & {
  animation: ${slideinFromBottom} 1s 0.4s both;
}
`

const Title = styled.div`font-size: ${ll(24)};
line-height: normal;
letter-spacing: 0.08em;
font-weight: 700;
border-bottom: ${ll(3)} dotted var(--green);
padding-bottom: ${ll(12)};
text-align: center;

  @media (max-width: 40rem){
    font-size: ${ll(20)};
line-height: normal;
letter-spacing: 0.08em;
font-weight: 700;
padding-bottom: ${ll(4)};
  }
  strong {
    color: var(--green);
  }
`

const ContainerInner = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: ${ll(49)};
  margin-top: ${ll(32)};
  justify-content: center;
  @media (max-width: 40rem){
    gap: ${ll(16)};
  margin-top: ${ll(30)};
  flex-direction: column;
  }
`

const IconContainer = styled.div`
  display: flex;
  
  gap: ${ll(25)};
  @media (max-width: 40rem){
    gap: ${ll(20)};
  }
  svg {
    height: ${ll(150)};
    display: block;
    width: auto;
    @media (max-width: 40rem){
      height: ${ll(120)};
    }
  }
`

const Text2 = styled.div`
font-size: ${ll(15)};
line-height: 1.8;
letter-spacing: 0.02em;
font-weight: 700;
@media (max-width: 40rem){
  font-size: ${ll(13)};
line-height: 1.5384615384615385;
letter-spacing: 0.02em;
font-weight: 700;
  
}
small {
  font-size: ${ll(12)};
line-height: 2.75;
letter-spacing: 0.02em;
font-weight: 700;
@media (max-width: 40rem){
  font-size: ${ll(9)};
line-height: 1.5555555555555556;
letter-spacing: 0.02em;
font-weight: 500;
}
}
strong {
  color: var(--green)
}
`


type TopHowToSectonProps = {

}
export const TopHowToSecton: FunctionComponent<TopHowToSectonProps> = () => {
  const isMobile = useBreakpoint('40rem')
  return <Outer id="howto">
    <Inner>
      <SectionHead label="HOW TO">
        ご利用方法
      </SectionHead>
      <List>
        <Animate>
          <Swiper
            slidesPerView="auto"
            centeredSlides={isMobile}
          >
            <SwiperSlide>
              <Item>
                <ImageContainer>
                  <SvgHi1 />
                </ImageContainer>
                <Num>
                  01
                </Num>
                <Text>
                  お好みのメニュー、<br />
                  数量からご希望のコースを選択し、<br />
                  Webでご注文ください。
                </Text>
              </Item>
            </SwiperSlide>
            <SwiperSlide>
              <Item>
                <ImageContainer>
                  <SvgHi2 />
                </ImageContainer>
                <Num>
                  02
                </Num>
                <Text>
                  ご希望のお届け日に、<br />
                  商品をお届けします。<br />
                  季節を問わず、室内で常温保存可能です。<br />
                  <small>※直射日光、高温多湿を避けて保存してください</small>
                </Text>
              </Item>
            </SwiperSlide>
            <SwiperSlide>
              <Item>
                <ImageContainer>
                  <SvgHi3 />
                </ImageContainer>
                <Num>
                  03
                </Num>
                <Text>
                  お召し上がりになる際は、<br />
                  電子レンジで袋のまま<br />
                  温めてください。
                </Text>
              </Item>
            </SwiperSlide>
          </Swiper>
        </Animate>
      </List>
      <Animate>
        <Container>
          <Title>
            手軽で便利な<Mbr /><strong>電子レンジ対応パウチ</strong>
          </Title>
          <ContainerInner>
            <IconContainer>
              <SvgRi1 />
              <SvgRi2 />
            </IconContainer>
            <Text2>
              「まめとくらす」は、電子レンジ対応パウチのレトルト食品です。<br />
              <strong>電子レンジの加熱でも湯せんでも、どちらも美味しく召し上がれます。</strong><br />
              電子レンジご使用時は、袋のまま自立させ加熱できるので、<Dbr />
              お皿もレンジも汚さずに、カンタン調理できます。<br />
              <small>※加熱時間などにつきましては、商品パッケージ（外箱）をご確認ください。</small>
            </Text2>
          </ContainerInner>
        </Container>
      </Animate>
    </Inner>
  </Outer>
}