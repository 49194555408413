import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-head'
import { ProductCard1, ProductCard2, ProductCard3 } from '../components/product-card'
import { pageMaxWidth } from '../styles/page-max-width'
import { ll } from '../utils/lcqw'
import { count } from '../styles/count'
import { slideinFromBottom } from '../styles/keyframes'

const Outer = styled.div`
background-color: var(--beige);
`

const Inner = styled.div`
  ${pageMaxWidth}
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::after {
    content: '';
    display: block;
    padding-bottom: ${ll(120)};
    @media (max-width: 40rem){
      padding-bottom: ${ll(70)};
    }
  }
`

const Text1 = styled.div`
  margin-top: ${ll(-20)};
  font-size: ${ll(22)};
line-height: normal;
letter-spacing: 0.02em;
font-weight: 500;
@media (max-width: 40rem){
  font-size: ${ll(14)};
line-height: normal;
letter-spacing: 0.02em;
font-weight: 500;
}
`

const List = styled.div`
  margin-top: ${ll(98)};
  @media (max-width: 40rem){
    margin-top: ${ll(49)};
  }
`

const Item = styled.div`
& + & {
  margin-top: ${ll(72)};
  @media (max-width: 40rem){
    margin-top: ${ll(62)};
  }
}
`

type TopOrderSectionProps = {

}
export const TopOrderSection: FunctionComponent<TopOrderSectionProps> = () => {
  return <Outer id="order">
    <Inner>
      <SectionHead label="Order" line>
        当サイトでは<br />
        1食あたり<strong><strong>約半額!!</strong></strong>
      </SectionHead>
      <Text1>
        毎月ご希望に合わせて定期的にお届け！
      </Text1>
      <List>
        <Item>
          <ProductCard1 />
        </Item>
        <Item>
          <ProductCard2 />
        </Item>
        <Item>
          <ProductCard3 />
        </Item>
      </List>
    </Inner>
  </Outer>
}