import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgArc from '../generated/svg/arc'
import SvgCheck from '../generated/svg/check'
import { ll } from '../utils/lcqw'
import { SectionHead } from '../components/section-head'
import { pageMaxWidth } from '../styles/page-max-width'
import { Animate } from '../components/animate'
import { count } from '../styles/count'
import { slideinFromBottom } from '../styles/keyframes'

const Outer = styled.div`
  position: relative;
  background-color: var(--beige);
  margin-top: ${ll(160)};
  padding-bottom: ${ll(120)};
  @media (max-width: 40rem){
    padding-bottom: ${ll(70)};
  }
`

const ArcContainer = styled.div`
position: absolute;
top: 0;
left: 0;
translate: 0 -100%;
color: var(--beige);
  svg {
    width: 100vw;
    height: auto;
    display: block;
  }
`

const Inner = styled.div`
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${pageMaxWidth}
`

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: fit-content;
  gap: ${ll(34)};
  @media (max-width: 40rem){
    grid-template-columns: repeat(2, 1fr);
    gap: ${ll(19)};
  }
`

const Item = styled.div`
  background-color: var(--brown);
  border-radius: 100rem;
  position: relative;
  aspect-ratio: 1 / 1;
  height: ${ll(224)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${ll(22)};
  box-sizing: border-box;
  @media (max-width: 40rem){
    height: ${ll(151)};
  padding-top: ${ll(16)};
  }
  &::before {
    content: '';
    inset: 0;
    margin: auto;
    width: 90%;
    border: ${ll(3)} dotted var(--beige);
    position: absolute;
    border-radius: 100rem;
    aspect-ratio: 1 / 1;
    pointer-events: none;
    @media (max-width: 40rem){
      border: ${ll(2)} dotted var(--beige);
    }
  }
  ${count}
  opacity: 0;
  .animate & {
    animation: ${slideinFromBottom} 1s calc(0.2s * var(--count)) both;
  }
`

const Check = styled.div`
  padding-left: ${ll(14)};
  @media (max-width: 40rem){
    padding-left: ${ll(6)};
  }
  svg {
    display: block;
    height: ${ll(52)};
    width: auto;
    @media (max-width: 40rem){
      height: ${ll(33)};
    }
  }
`

const Text = styled.div`
font-size: ${ll(20)};
line-height: 1.45;
letter-spacing: 0.04em;
font-weight: 700;
color: white;
text-align: center;
margin-top: ${ll(10)};
@media (max-width: 40rem){
  font-size: ${ll(13.5)};
line-height: 1.4814814814814814;
letter-spacing: 0.04em;
font-weight: 700;
margin-top: ${ll(7)};
}
strong {
  color: #EDFFC3;
}
`

const SmallText = styled(Text)`
  font-size: ${ll(18)};
line-height: 1.3888888888888888;
font-weight: 700;
@media (max-width: 40rem){
  font-size: ${ll(13)};
line-height: 1.3076923076923077;
font-weight: 700;
}
`

type TopRecommendSectionProps = {

}
export const TopRecommendSection: FunctionComponent<TopRecommendSectionProps> = () => {
  return <Outer>
    <ArcContainer>
      <SvgArc />
    </ArcContainer>
    <Inner>
      <SectionHead label="Check!" border>
        こんな方に<strong>オススメ！</strong>
      </SectionHead>
      <Animate>
        <List>
          <Item>
            <Check>
              <SvgCheck />
            </Check>
            <Text>
              <strong>食物繊維や<br />
              たんぱく質不足が</strong><br />
              気になる方
            </Text>
          </Item>
          <Item>
            <Check>
              <SvgCheck />
            </Check>
            <Text>
              <strong>カロリーや脂質</strong><br />
              の摂取を<br />
              抑えたい方
            </Text>
          </Item>
          <Item>
            <Check>
              <SvgCheck />
            </Check>
            <SmallText>
              <strong>プラントベースや<br />
              グルテンフリー</strong>な<br />
              食生活を<br />
              実践したい方
            </SmallText>
          </Item>
          <Item>
            <Check>
              <SvgCheck />
            </Check>
            <Text>
              <strong>健康や美容を</strong><br />
              意識した食生活を<br />
              送りたい方
            </Text>
          </Item>
        </List>
      </Animate>
    </Inner>
  </Outer>
}