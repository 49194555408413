import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-head'
import dayjs from 'dayjs'
import { ll } from '../utils/lcqw'
import { pageMaxWidth } from '../styles/page-max-width'
import { Animate } from '../components/animate'
import { count } from '../styles/count'
import { slideinFromRight } from '../styles/keyframes'

const Outer = styled.div`
background-color: var(--beige);
@media (max-width: 40rem){
  
}
`

const Inner = styled.div`
display: flex;
flex-direction: column;
align-items: center;
container-type: inline-size;
${pageMaxWidth}
  &::after {
    content: '';
    display: block;
  }

`

const List = styled.div`
display: grid;
grid-template-columns: auto 1fr;
width: ${ll(850)};
@media (max-width: 40rem){
  grid-template-columns: 1fr;
  width: 100%;
}
`

const Item = styled.div`
display: grid;
grid-template-columns: subgrid;
grid-column: span 2;
padding: ${ll(16)} ${ll(23)};
border-bottom: 1px dotted currentColor;
@media (max-width: 40rem){
  padding: ${ll(23)} 0;
  grid-column: auto;
}
${count}
  opacity: 0;
.animate & {
  animation: ${slideinFromRight} 1s calc(0.2s * var(--count)) both;
}
`

const PublishAt = styled.div`
font-size: ${ll(15)};
line-height: 2.3333333333333335;
letter-spacing: 0.04em;
font-weight: 500;
@media (max-width: 40rem){
  font-size: ${ll(13)};
line-height: 1.3846153846153846;
letter-spacing: 0.04em;
font-weight: 500;
}
`

const Text = styled.div`
font-size: ${ll(15)};
line-height: 2.3333333333333335;
letter-spacing: 0.04em;
font-weight: 500;
@media (max-width: 40rem){
  font-size: ${ll(13)};
line-height: 1.3846153846153846;
letter-spacing: 0.04em;
font-weight: 500;
margin-top: ${ll(6.5)};
}
`

const news: News[] = [
  {
    publishAt: '2024-05-23T12:00:00.000Z',
    text: '「まめとくらす」サイトOPEN！'
  }
]

type News = {
  publishAt: string
  text: string
}

type TopNewsSectionProps = {

}
export const TopNewsSection: FunctionComponent<TopNewsSectionProps> = () => {
  return <Outer>
    <Inner>
      <SectionHead label="News">
        お知らせ
      </SectionHead>
      <Animate>
        <List>
          {
            news.map((news, i) => {
              return <Item key={i}>
                <PublishAt>
                  {dayjs(news.publishAt).format('YYYY.MM.DD')}
                </PublishAt>
                <Text>
                  {news.text}
                </Text>
              </Item>
            })
          }
        </List>
      </Animate>
    </Inner>

  </Outer>
}